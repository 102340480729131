import React, { FC } from 'react'

import PartnersCarousel from '@/components/PartnersCarousel'

import DefaultComponentProps from '@/types/DefaultComponentProps'

export const Partners: FC<DefaultComponentProps> = ({ className = '' }) => {
  return (
    <section className={className}>
      <PartnersCarousel />
    </section>
  )
}
